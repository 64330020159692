
export enum PlayerAnimationDirections {
    Down = 'down',
    Left = 'left',
    Up = 'up',
    Right = 'right',
}
export enum PlayerAnimationTypes {
    Walk = 'walk',
    Idle = 'idle',
}


